import React from "react"
import "../styles/home.scss"
import { Business, Monument } from "../../components/svg"

const SectionTwo = props => (
  <div class="sectionTwo">
    <div class="iconsArea">
      <div class="icons">
        <div className="svgIcon">
          <Business />
        </div>

        <p class="title">Mision</p>
        <p>Satisfacer a nuestros clientes ofreciendo servicios y productos financieros de calidad con confianza y calidez, trabajamos en función de las necesidades de cada uno de ellos, brindando Asesoría financiera, Asesoría en tecnología y Asesoría en estructuración de portafolios y Consultorías.</p>
      </div>
      <div class="icons">
        <div className="svgIcon">
          <Monument />
        </div>

        <p class="title">Vision</p>
        <p>Ser la entidad financiera líder, brindándoles fidelidad a nuestros clientes y ganándonos su confianza, logrando ser la mejor alternativa, con servicios de calidad que contribuyen con el bienestar económico y social del país.</p>
      </div>
    </div>
  </div>
)

export default SectionTwo

import React from "react"
import "../styles/home.scss"
import Logo  from "../../assets/img/logo-caribe.png"
//import PlayBlocks from "../../assets/img/play-blocks.png"

const SectionThree = props => (
  <div class="sectionThree">
    <div class="sectionContainer">
      <p class="title">Quienes Somos</p>
      <p class="content">
        Somos Caribe Group LLC, una entidad financiera que dispone de un grupo de asesores que acompañan a nuestros clientes 
        en el proceso de desarrollo y crecimiento de sus empresas, nuestro Mercado meta son las pequeñas y medianas empresas 
        que requieren soluciones financieras a corto y mediano plazo. Iniciamos operaciones desde el 2017 y desde entonces 
        nuestro crecimiento ha sido constante.
      </p>
    </div>

    {/* <img src={PlayBlocks} alt="Play-blocks" /> */}
  </div>
)

export default SectionThree

import React from "react"
import "../styles/home.scss"
import SectionOne from "./sectionOne"
import SEO from "../../components/seo"
import Header from "../../components/header"
//import Furniture from "../../assets/img/furniture_person.png"
import Path from "../../assets/img/path1.png"
import SectionTwo from "./sectionTwo"
import SectionThree from "./sectionThree"
import SectionFour from "./sectionFour"
import SectionFive from "./sectionFive"
import Footer from "../../components/footer"

const Home = props => (
  <div>
    <SEO title="Home" />
    <SectionOne />
    {/* <img id="path" alt="path" src={Path} /> */}
    {/* <img id="furniture" alt="person-furniture" src={Furniture} /> */}
    <SectionThree />
    <SectionTwo />
    <SectionFour />
    <SectionFive />
    <Footer />
  </div>
)

export default Home

import React from "react"
import "../styles/home.scss"
import phoneBack from "../../assets/img/EURO01.png"
import phoneFront from "../../assets/img/Euro02.png"
import { Icon } from "../../components/svg"
//import Icon from "../../assets/img/icon.png"

const SectionFive = props => (
  <div class="sectionFive">
    <h1>Contactanos para mas informacion</h1>
    <form>
      <div>
        <input placeholder="Nombre y Apellido" />
        <input placeholder="Correo Electronico" />
        <input placeholder="Numero de Telefono" />
      </div>
      <div>
        <textarea placeholder="Mensaje"></textarea>
      </div>

    </form>
  </div>
)

export default SectionFive

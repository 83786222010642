import React from "react"
import { Facebook, Twitter, Instagram } from "./svg"
import Logo from "../assets/img/logo-caribe.png"
import "./styles/footer.scss"
import { Link } from "gatsby"

const Footer = props => (
  <footer>
    <div>
      <img src={Logo} />
      <p class="text">info@caribegroupllc.com</p>
      <p class="text">&copy; Caribe Group LLC 2020</p>
    </div>
    <div>
      <p>
        <span>
          <Facebook />
        </span>{" "}
        Facebook
      </p>
      <p>
        <span>
          <Twitter />
        </span>{" "}
        Twitter
      </p>
      <p>
        <span>
          <Instagram />
        </span>{" "}
        Instagram
      </p>
    </div>
    <div>
      <p>
        Confidentiality Notice: This platform contains information belonging to
        EuroTrust that (a) is or may be CONFIDENTIAL, PROPERTY IN NATURE, OR
        OTHERWISE PROTECTED BY LAW DISCLOSURE, and (b) is intended only for the
        use of the herein third party and EuroTrust. If you are not an intended
        client, you are hereby notified that any disclosure, copying,
        distribution or the taking of any action in reliance on the contents of
        said information is stickily prohibited. This message is not intended as
        an offer, recommendation or solicitation to buy or sell, nor is it an
        official confirmation of terms, actual or proposed, and no
        representation or warranty is made that this information is complete or
        accurate.
      </p>
    </div>
  </footer>
)

export default Footer
